<template>
  <div id="organization">
    <loading :active="loading" :is-full-page="true"></loading>
    <link
      href="https://fonts.googleapis.com/icon?family=Material+Icons"
      rel="stylesheet"
    />

    <seall-header :organization="organization"></seall-header>

    <main class="main-organization">
      <carousel-cards></carousel-cards>

      <div class="page-organization-form">
        <div class="div-organization-form">
          <h2>{{ $t("organization.title") }}</h2>
          <h3>{{ $t("organization.subtitle") }}</h3>
          <form action>
            <div class="form-organization-line1">
              <div
                class="form-organization-column1-img"
                v-on:click="eventListener"
              >
                <div class="max-width">
                  <div class="imageContainer">
                    <div>
                      <img
                        :src="
                          organization.photoPath
                            ? organization.photoPath
                            : require('../images/camera.png')
                        "
                        alt="Selecione uma imagem"
                        id="imgPhoto"
                      />
                    </div>
                  </div>
                </div>
                <input
                  type="file"
                  id="flImage"
                  name="fImage"
                  accept="image/*"
                />
              </div>

              <div class="form-organization-column1-texts">
                <div class="form-organization-column-text">
                  <p>
                    {{ $t("organization.itens.name") }}
                    <span>*</span>
                  </p>
                  <input
                    id="data_nome"
                    class="input-organization"
                    :style="{ border: checkField(organization.name) }"
                    v-model="organization.name"
                    type="text"
                    :placeholder="$t('organization.itens.name_placeholder')"
                  />
                  <div>
                    <span
                      class="error-field"
                      v-if="organization && organization.name === null"
                      >{{ $t("main.required_field") }}</span
                    >
                  </div>
                </div>

                <div class="form-organization-column-text">
                  <p>{{ $t("organization.itens.cnpj") }}</p>
                  <input
                    id="data_cnpj"
                    v-model="organization.cnpj"
                    class="input-organization"
                    type="text"
                    :placeholder="$t('organization.itens.cnpj_placeholder')"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="w-50">
                <p>
                  {{ $t("organization.itens.segment") }}
                  <span>*</span>
                </p>

                <Select2
                  :class="{
                    invalid: organization.segment === null,
                  }"
                  v-model="organization.segment"
                  :options="segments"
                  :settings="settingsSelect2"
                ></Select2>

                <div>
                  <span
                    class="error-field"
                    v-if="organization && organization.segment === null"
                    >{{ $t("main.required_field") }}</span
                  >
                </div>
              </div>

              <div class="w-50">
                <p>
                  {{ $t("organization.itens.type") }}
                  <span>*</span>
                </p>

                <Select2
                  :class="{
                    invalid: organization.type === null,
                  }"
                  v-model="organization.type"
                  :options="types"
                  :settings="settingsSelect2"
                ></Select2>

                <div>
                  <span
                    class="error-field"
                    v-if="organization && organization.type === null"
                    >{{ $t("main.required_field") }}</span
                  >
                </div>
              </div>
            </div>

            <div class="row" style="max-width: 100%">
              <div class="w-33">
                <p>
                  {{ $t("organization.itens.country") }}
                  <span>*</span>
                </p>

                <Select2
                  :class="{
                    invalid: organization.country === null,
                  }"
                  @select="loadStates()"
                  v-model="organization.country"
                  :settings="settingsSelect2"
                  :options="countries_options"
                >
                </Select2>

                <div>
                  <span
                    class="error-field"
                    v-if="organization && organization.country === null"
                    >{{ $t("main.required_field") }}</span
                  >
                </div>
              </div>
              <div class="w-33">
                <p>
                  {{ $t("organization.itens.state") }}
                  <span>*</span>
                </p>

                <Select2
                  :class="{
                    invalid: organization.state === null,
                  }"
                  @select="loadCities()"
                  v-model="organization.state"
                  :settings="settingsSelect2"
                  :options="states"
                >
                </Select2>
                <div>
                  <span
                    class="error-field"
                    v-if="organization && organization.state === null"
                    >{{ $t("main.required_field") }}</span
                  >
                </div>
              </div>
              <div class="w-33">
                <p>
                  {{ $t("organization.itens.city") }}
                  <span>*</span>
                </p>

                <Select2
                  :class="{
                    invalid: organization.city === null,
                  }"
                  v-model="organization.city"
                  :settings="settingsSelect2"
                  :options="cities"
                >
                </Select2>
                <div>
                  <span
                    class="error-field"
                    v-if="organization && organization.city === null"
                    >{{ $t("main.required_field") }}</span
                  >
                </div>
              </div>
            </div>

            <div class="form-organization-line2">
              <div class="form-organization-column-tipo">
                <p>
                  {{ $t("organization.itens.number") }}
                  <span>*</span>
                </p>
                <input
                  id="data_numero"
                  v-model="organization.quantityEmployee"
                  class="input-organization"
                  type="number"
                  :style="{ border: checkField(organization.quantityEmployee) }"
                  :placeholder="$t('organization.itens.number')"
                />
                <div>
                  <span
                    class="error-field"
                    v-if="
                      organization && organization.quantityEmployee === null
                    "
                    >{{ $t("main.required_field") }}</span
                  >
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="div-organization-img">
          <div class="main-login-svgs-create">
            <svg width="140" height="140" class="svg-ods3">
              <circle cx="70" cy="70" r="70" fill="#4C9F38" />
              <foreignObject x="10" y="20" width="120" height="120">
                <div xmlns="http://www.w3.org/1999/xhtml">
                  <div
                    class="map-radar-card-text"
                    style="
                      display: flex;
                      flex-direction: row;
                      height: 30%;
                      width: 100%;
                    "
                  >
                    <p
                      style="
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-items: center;
                        justify-content: flex-end;
                        padding: 0vh 0vw;
                        margin: 0vh 0vw;
                        color: #ffffff;
                        font-family: 'Oswald', sans-serif;
                        font-size: 30px;
                        width: 30%;
                        font-weight: bold;
                        font-size: 28px;
                      "
                    >
                      3
                    </p>
                    <span
                      style="
                        display: flex;
                        align-items: flex-start;
                        padding: 5px;
                        margin: 0vh 0vw;
                        color: #ffffff;
                        font-family: 'Oswald', sans-serif;
                        font-weight: bold;
                        font-size: 10px;
                        text-transform: uppercase;
                        width: 70%;
                      "
                      >{{ $t("map.ods.ods3.name") }}</span
                    >
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      align-content: center;
                      justify-items: center;
                      justify-content: center;
                      height: 80%;
                    "
                  >
                    <img style="width: 50%" src="./../images/ods-icon3.svg" />
                  </div>
                </div>
              </foreignObject>
            </svg>
            <svg width="140" height="140" class="svg-ods4">
              <circle cx="70" cy="70" r="70" fill="#C5192D" />
              <foreignObject x="10" y="20" width="120" height="120">
                <div xmlns="http://www.w3.org/1999/xhtml">
                  <div
                    class="map-radar-card-text"
                    style="
                      display: flex;
                      flex-direction: row;
                      height: 30%;
                      width: 100%;
                    "
                  >
                    <p
                      style="
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-items: center;
                        justify-content: flex-end;
                        padding: 0vh 0vw;
                        margin: 0vh 0vw;
                        color: #ffffff;
                        font-family: 'Oswald', sans-serif;
                        font-size: 30px;
                        width: 30%;
                        font-weight: bold;
                        font-size: 28px;
                      "
                    >
                      4
                    </p>
                    <span
                      style="
                        display: flex;
                        align-items: flex-start;
                        padding: 5px;
                        margin: 0vh 0vw;
                        color: #ffffff;
                        font-family: 'Oswald', sans-serif;
                        font-weight: bold;
                        font-size: 10px;
                        text-transform: uppercase;
                        width: 70%;
                      "
                      >{{ $t("map.ods.ods4.name") }}</span
                    >
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      align-content: center;
                      justify-items: center;
                      justify-content: center;
                      height: 80%;
                    "
                  >
                    <img style="width: 50%" src="./../images/ods-icon4.svg" />
                  </div>
                </div>
              </foreignObject>
            </svg>
            <svg width="140" height="140" class="svg-ods11">
              <circle cx="70" cy="70" r="70" fill="#FD9D24" />
              <foreignObject x="10" y="20" width="120" height="120">
                <div xmlns="http://www.w3.org/1999/xhtml">
                  <div
                    class="map-radar-card-text"
                    style="
                      display: flex;
                      flex-direction: row;
                      height: 30%;
                      width: 100%;
                    "
                  >
                    <p
                      style="
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-items: center;
                        justify-content: flex-end;
                        padding: 0vh 0vw;
                        margin: 0vh 0vw;
                        color: #ffffff;
                        font-family: 'Oswald', sans-serif;
                        font-size: 30px;
                        width: 30%;
                        font-weight: bold;
                        font-size: 28px;
                      "
                    >
                      11
                    </p>
                    <span
                      style="
                        display: flex;
                        align-items: flex-start;
                        padding: 2px;
                        margin: 0vh 0vw;
                        color: #ffffff;
                        font-family: 'Oswald', sans-serif;
                        font-weight: bold;
                        font-size: 10px;
                        text-transform: uppercase;
                        width: 70%;
                      "
                      >{{ $t("map.ods.ods11.name") }}</span
                    >
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      align-items: center;
                      align-content: center;
                      justify-items: center;
                      justify-content: center;
                      height: 80%;
                    "
                  >
                    <img style="width: 50%" src="./../images/ods-icon11.svg" />
                  </div>
                </div>
              </foreignObject>
            </svg>
          </div>
        </div>
      </div>
    </main>

    <footer class="footer-organization">
      <img src="./../images/barra_ods.svg" alt="Barra" style="width: 100%" />
      <div class="div-organization-footer-button">
        <powered-by></powered-by>
        <button
          type="button"
          class="organization-modal-button-continue"
          :disabled="!canSave()"
          v-on:click="save()"
        >
          {{ $t("organization.button_bottom") }} &#10132;
        </button>
      </div>
    </footer>
  </div>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import AuthService from "../../services/auth/auth";
import OrganizationService from "../../services/organization/organization.service.js";
import UniversalCountryService from "../../services/universal-country/universal-country.service";
import SeallHeader from "./Header.vue";
import PoweredBy from "./PoweredBy.vue";
import CarouselCards from "./CarouselCards.vue";
import { cloneDeep } from "lodash";
import Select2 from "vue3-select2-component";
export default {
  name: "organization",
  data() {
    return {
      valor: 4.5,
      photo: "",
      organization: { id: null },
      langs: [
        ["en", "en-US"],
        ["es", "es-EN"],
        ["pt", "pt-BR"],
      ],
      countries_options: [],
      countries: [],
      cities: [],
      states: [],
      loading: false,
      settingsSelect2: {
        width: "resolve",
        language: "pt-BR",
      },
      segments: [],
      types: [],
    };
  },
  watch: {
    "$i18n.locale"() {
      this.loadSelects();
    },
  },
  mounted() {
    if (window.screen.availWidth < 1025) this.valor = 1.25;
    else this.valor = 4.5;

    const cogId = AuthService.getCogId();
    this.loading = true;
    OrganizationService.getByCreatedBy(cogId)
      .then((org) => {
        this.organization = org.data;
        this.loadSelects();
      })
      .finally(async () => {
        if (this.organization.id) {
          localStorage.setItem(
            "organization",
            JSON.stringify(this.organization)
          );
        } else {
          localStorage.removeItem("organization");
        }

        UniversalCountryService.getAll().then(async (response) => {
          this.countries = response.data.body;
          this.countries_options = this.countries.map((c) => {
            return { id: c.name, text: c.name };
          });
          this.loading = false;

          if (this.organization.id != null) {
            if (!this.organization.country) {
              this.organization.country = "Brazil";
            }

            this.states = this.countries
              .find((country) => country.name === this.organization.country)
              .states.map((state) => state.name);

            if (this.organization.state) {
              this.loadCities();
            }
          }
        });
      });
  },
  methods: {
    eventListener: function () {
      let photo = document.getElementById("imgPhoto");
      let file = document.getElementById("flImage");
      file.click();

      if (file.files.lenght <= 0) return;

      file.addEventListener("change", () => {
        let reader = new FileReader();
        reader.onload = () => {
          photo.src = reader.result;
        };
        reader.readAsDataURL(file.files[0]);
        if (this.photo != file.files[0]) {
          this.photo = file.files[0];
          this.uploadPhoto();
        }
      });
    },
    canSave() {
      if (this.organization.name === "" || !this.organization.name)
        return false;
      if (this.organization.segment === "" || !this.organization.segment)
        return false;
      if (this.organization.type === "" || !this.organization.type)
        return false;
      if (this.organization.country === "" || !this.organization.country)
        return false;
      if (this.organization.city === "" || !this.organization.city)
        return false;
      if (this.organization.state === "" || !this.organization.state)
        return false;
      return true;
    },
    save() {
      if (!this.canSave()) {
        this.$notify({
          title: this.$t("main.form_invalid"),
          type: "warn",
        });
        return;
      }

      this.loading = true;
      if (this.organization.id != null) {
        OrganizationService.update(
          this.organization.id,
          this.organization
        ).then((org) => {
          this.organization = org.data;
          this.loading = false;
          window.location = "/legacy";
        });
      } else {
        OrganizationService.create(this.organization).then((org) => {
          this.organization = org.data;
          this.loading = false;
          window.location = "/legacy";
        });
      }
    },
    uploadPhoto: function () {
      this.loading = false;
      localStorage.setItem(
        "organization",
        JSON.stringify(this.organization),
        true
      );

      const formData = new FormData();
      formData.append("photo", this.photo);
      this.loading = true;
      OrganizationService.uploadPhoto(this.organization.id, formData)
        .then(() => {
          OrganizationService.getByCreatedBy(AuthService.getCogId()).then(
            (org) => (this.organization.photoPath = org.data.photoPath)
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    checkField(field) {
      if (field && field != "") {
        return "1px solid #c9baeb";
      } else {
        return "1px solid red";
      }
    },
    loadStates() {
      this.states = this.countries
        .find((c) => c.name === this.organization.country)
        .states.map((state) => state.name);
    },
    loadCities() {
      this.cities = this.countries
        .find((c) => c.name === this.organization.country)
        .states.find((state) => this.organization.state === state.name).cities;
    },
    logout() {
      this.$store.dispatch("auth/logout");
    },
    loadSelects() {
      this.segments = [
        { id: "A", text: this.$t("organization.areas.A.text") },
        { id: "B", text: this.$t("organization.areas.B.text") },
        { id: "C", text: this.$t("organization.areas.C.text") },
        { id: "D", text: this.$t("organization.areas.D.text") },
        { id: "E", text: this.$t("organization.areas.E.text") },
        { id: "F", text: this.$t("organization.areas.F.text") },
        { id: "G", text: this.$t("organization.areas.G.text") },
        { id: "H", text: this.$t("organization.areas.H.text") },
        { id: "I", text: this.$t("organization.areas.I.text") },
        { id: "J", text: this.$t("organization.areas.J.text") },
        { id: "K", text: this.$t("organization.areas.K.text") },
        { id: "L", text: this.$t("organization.areas.L.text") },
        { id: "M", text: this.$t("organization.areas.M.text") },
        { id: "N", text: this.$t("organization.areas.N.text") },
        { id: "O", text: this.$t("organization.areas.O.text") },
        { id: "P", text: this.$t("organization.areas.P.text") },
        { id: "Q", text: this.$t("organization.areas.Q.text") },
        { id: "R", text: this.$t("organization.areas.R.text") },
        { id: "S", text: this.$t("organization.areas.S.text") },
        { id: "T", text: this.$t("organization.areas.T.text") },
        { id: "U", text: this.$t("organization.areas.U.text") },
      ];

      this.types = [
        { id: "public", text: this.$t("organization.types.public") },
        { id: "private", text: this.$t("organization.types.private") },
        {
          id: "third_sector",
          text: this.$t("organization.types.third_sector"),
        },
        {
          id: "impact_business",
          text: this.$t("organization.types.impact_business"),
        },
      ];
    },
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Loading,
    SeallHeader,
    Select2,
    PoweredBy,
    CarouselCards,
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: Nunito-Regular;
  src: url(../fonts/Nunito/Nunito-Regular.ttf);
}
@font-face {
  font-family: NunitoSans-Regular;
  src: url(../fonts/Nunito_Sans/NunitoSans-Regular.ttf);
}
@font-face {
  font-family: Poppins-Regular;
  src: url(../fonts/Poppins/Poppins-Regular.ttf);
}
@font-face {
  font-family: "Roboto", sans-serif;
  src: url(../fonts/Lato/Lato-Regular.ttf);
}

html,
body {
  border: 0vh 0vw;
  height: 100%;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
  width: 100%;
}

body {
  display: flex;
  flex-direction: column;
  padding: 0;
}

body {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));

  & > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-top: var(--bs-gutter-y);
  }
}

.select2-results__option {
  font-family: "Roboto", sans-serif;
}

.select2-selection span {
  font-family: "Roboto", sans-serif;
}

.w-50 {
  flex: 0 0 auto;
  width: 50%;
}

.w-33 {
  flex: 0 0 auto;
  width: 33%;
}

.error-field {
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: red !important;
}

.header-organization {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2vh 3vw;
}

.div-organization-header-links {
  text-decoration: none;
}

.organization-header-link {
  color: #a3a3a3;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  padding: 0vh 2vw;
  text-decoration: none;
}

.organization-header-button {
  color: #2f1764;
  background-color: #ffffff;
  border-color: #2f1764;
  border-radius: 5px;
  border-style: solid;
  border-width: thin;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  margin: 0vh 0vw;
  padding: 1vh 3vw;
}

.material-icons {
  color: #000000;
}

.div-organization-header-photo {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 40%;
}

.div-organization-header-photo i {
  padding: 0vh 1vw 0vh 3vw;
}

.div-organization-header-photo img {
  border-radius: 100%;
  overflow: hidden;
  height: 10%;
  width: 10%;
}

.main-organization {
  background-color: #ffffff;
}

.div-organization-cards {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2vh 0vw 0vh 1vw;
}

.organization-card {
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  min-height: 80px;
  max-height: 80px;
  margin: 0vh 1vw;
  padding: 5vh 1vw;
  width: 25vw;
}

.organization-card p {
  color: #687d9b;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 110%;
  padding: 0vh 0.5vw;
}

.organization-card img {
  width: 4vw;
}

.page-organization-form {
  display: flex;
  flex-direction: row;
}

input[type="file"] {
  display: none;
}

.max-width {
  width: 100%;
}

#imgPhoto {
  height: 100%;
  width: 100%;
  background-color: #eee;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.5s;
  max-width: 300px;
  max-height: 300px;
}

#imgPhoto:hover {
  background-color: #ccc;
}

#txtPhoto {
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: 800;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: #533a8b;
  cursor: pointer;
}

.div-organization-form {
  background-color: #ffffff;
  position: relative;
  z-index: 4;
  display: flex;
  flex-direction: column;
  padding: 10vh 0vw 15vh 5vw;
  width: 70%;
}

.div-organization-form h2 {
  color: #492e85;
  font-family: Poppins-Regular;
  font-style: normal;
  font-weight: bold;
  font-size: 46px;
  line-height: 120%;
  color: #492e85;
  margin: 0vh 0vw;
  padding: 2vh 0vw;
}

.div-organization-form h3 {
  color: #000000;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 19.522px;
  line-height: 29px;
  letter-spacing: 0.05em;
  margin: 0vh 0vw;
  padding: 3vh 0vw;
  width: 40vw;
}

.form-organization-line1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.form-organization-column1-img {
  width: 20vw;
  padding-right: 5vw;
}

.form-organization-column1-texts {
  width: 40vw;
  padding-bottom: 5vh;
}

.form-organization-column-text input {
  width: 100%;
}

.form-organization-line2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.form-organization-column-tipo {
  width: 100%;
}

.form-organization-line3 {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-organization-column-loc {
  margin-right: 1rem;
}

.form-organization-line3 #data_cidade {
  width: 30vw;
}

.div-organization-form #data_pais {
  width: 45vw;
}

.div-organization-form p {
  color: #191c1f;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  margin: 0vh 0vw;
  padding: 3vh 0vw 1vh;
}

.div-organization-form span {
  color: #00b9bd;
}

.div-organization-form .input-organization {
  background: #ffffff;
  border: 1px solid #c9baeb;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  padding: 0.5rem 0.5rem;
}

.organization-modal-button-continue {
  color: #ffffff;
  background-color: #2f1764ff;
  border-color: #2f1764;
  border-radius: 5px;
  border-style: solid;
  border-width: thin;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  margin: 5vh 0vw;
  padding: 1vh 2vw;
}

.organization-modal-button-continue:disabled {
  color: #ffffff;
  background-color: #a3a3a3;
  border-color: #fff;
  border-radius: 5px;
  border-style: solid;
  border-width: thin;
  font-family: Nunito-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  margin: 5vh 0vw;
  padding: 1vh 2vw;
}

.div-organization-img {
  background-image: url("./../images/result.png");
  width: 100%;
  background-repeat: round;
  background-size: cover;
}

.main-login-svgs-create {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 5;
  padding: 0vh 0vw;
  margin: 0vh 0vw;
}

.svg-ods3 {
  position: absolute;
  padding: 10vh 0vw 0vh 30vw;
}

.svg-ods4 {
  position: absolute;
  padding: 50vh 0vw 0vh 15vw;
}

.svg-ods11 {
  position: absolute;
  padding: 80vh 0vw 0vh 25vw;
}

.footer-organization {
  margin: 0vh 0vw;
  padding: 0vh 0vw;
  display: flex;
  flex-direction: column;
}

.div-organization-footer-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0vh 20vw;
}

select {
  display: block;
  width: 100%;
  min-width: 200px;
  padding: 0.375 rem 0.75 rem;
  font-size: 1rem;
  font-weight: 400;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 4px !important;
  padding: 1vh 0vw 1vh 0vw;
}

@media screen and (max-width: 1025px) {
  html,
  body {
    border: 0vh 0vw;
    height: 100%;
    margin: 0vh 0vw;
    padding: 0vh 0vw;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .w-50,
  .w-33 {
    width: 100% !important;
  }

  .header-organization {
    display: flex;
    flex-direction: column;
    justify-items: center;
  }

  .div-organization-header-links {
    text-decoration: none;
    padding: 3vh 0vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .div-organization-header-photo {
    justify-content: center;
    width: 100%;
  }

  .div-organization-header-photo img {
    border-radius: 100%;
    overflow: hidden;
    height: 10%;
    width: 10%;
  }

  .div-organization-header-img img {
    width: 40vw;
  }

  .div-questions-cards {
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 2vh 0vw 2vh 1vw;
    width: 20%;
  }

  .organization-card {
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    height: 10vh;
    margin: 0vh 0vw;
    padding: 5vh 3vw;
    width: 75vw;
  }

  .organization-card img {
    width: 10vw;
  }

  .div-organization-form {
    display: flex;
    flex-direction: column;
    width: 100vw;
    padding: 0vh 12.5vw;
    margin: 0vh 0vw;
  }

  .div-organization-form h2 {
    width: 75vw;
  }

  .div-organization-form h3 {
    width: 75vw;
  }

  .max-width {
    width: 90vw;
    padding: 0vh 0vw;
    margin: 0vh 0vw;
  }

  .imageContainer div {
    padding: 0vh 25%;
  }

  #txtPhoto {
    width: 100vw;
    margin: 0vh 0vw;
    padding: 5vh 0vw;
  }

  .form-organization-line1 {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    width: 75vw;
    padding: 0vh 0vw;
  }

  .form-organization-column1-img {
    width: 90vw;
  }

  .form-organization-column1-texts {
    width: 75vw;
    padding: 0vh 0vw;
  }

  .form-organization-column-text input {
    width: 100%;
    padding: 0vh 0vw;
  }

  .form-organization-line2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 75vw;
    padding: 0vh 0vw;
  }

  .form-organization-column-tipo input {
    width: 75vw;
    padding: 0vh 0vw;
  }

  .form-organization-line3 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 75vw;
    padding: 0vh 0vw;
  }

  .form-organization-line3 #data_uf {
    width: 75vw;
  }

  .form-organization-line3 #data_cidade {
    width: 75vw;
  }

  .div-organization-form #data_pais {
    width: 75vw;
  }

  #imgPhoto {
    margin-top: 10%;
    height: 200px;
    width: 200px;
    padding: 0.5vh;
    background-color: #eee;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.5s;
  }

  .div-organization-img {
    display: none;
    width: 0vw;
  }

  .organization-modal-button-continue {
    width: 75vw;
  }
}
</style>